
















import Vue from "vue";
import EntityCandidate from "@/components/widgets/EntityCandidate.vue";

export default Vue.extend({
  name: "PairwiseTable",
  components: {
    EntityCandidate,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      headers: [
        {
          text: "Entity A",
          value: "entity_a",
        },
        {
          text: "Entity B",
          value: "entity_b",
        },
        {
          text: "Cosine similarity score",
          value: "similarity_score",
        },
      ],
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
});
