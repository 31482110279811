




















import Vue from "vue";

import * as backend from "@/funcs/backend-requests";
import * as docs from "@/resources/docs/docs";

import DataExplorerTable from "@/components/tables/DataExplorerTable.vue";

export default Vue.extend({
  name: "DataExplorer",
  components: {
    DataExplorerTable,
  },
  data() {
    return {
      docs: docs,
      dataItems: [],
      dictionary: "opengwas",
      dictionaryOptions: [],
    };
  },
  computed: {
    //
  },
  watch: {
    //
  },
  mounted: async function (): Promise<void> {
    this.dictionaryOptions = await backend.getDictionaryOptions();
    await this.updateTable();
  },
  methods: {
    async updateTable(): Promise<void> {
      this.dataItems = await backend.getEntityInfoList(this.dictionary);
    },
  },
});
