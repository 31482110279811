






import Vue from "vue";

export default Vue.extend({
  name: "DataExplorerTable",
  components: {
    //
  },
  props: {
    items: Array,
  },
  data() {
    return {
      headers: [
        {
          text: "Entity id",
          value: "id",
        },
        {
          text: "Entity label",
          value: "label",
        },
        {
          text: "Description",
          value: "description",
        },
      ],
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
});
