












import Vue from "vue";
import Prism from "vue-prism-component";
import "prismjs/components/prism-python";

import Pairwise from "@/components/Pairwise.vue";

import * as tutorialDocs from "@/resources/docs/api-examples";

export default Vue.extend({
  name: "PairwiseView",
  components: {
    Pairwise,
    Prism,
  },
  data() {
    return {
      tab: 0,
      tutorialDocs: tutorialDocs,
    };
  },
  computed: {
    //
  },
  watch: {
    //
  },
  mounted: async function (): Promise<void> {
    //
  },
  methods: {
    //
  },
});
