


























import Vue from "vue";
import * as docs from "@/resources/docs/docs";

import * as backend from "@/funcs/backend-requests";

export default Vue.extend({
  name: "About",
  components: {
    //
  },
  data() {
    return {
      docs: docs,
      dictionary: [],
      infoEsStatus: null,
      infoDocSizes: null,
    };
  },
  computed: {
    //
  },
  watch: {
    //
  },
  mounted: async function (): Promise<void> {
    this.dictionary = await backend.getDictionaryOptions();
    this.infoEsStatus = await backend.getEsStatus();
    this.infoDocSizes = await Promise.all(
      this._.chain(this.dictionary)
        .map(async function (e) {
          const dictionaryLength = await backend.getDictionaryLength(e);
          const res = {
            index: e,
            document_size: dictionaryLength,
          };
          return res;
        })
        .value(),
    );
  },
  methods: {
    //
  },
});
